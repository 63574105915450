<template>
  <NuxtLayout :name="layoutName">
    <div class="relative">
      <NuxtErrorBoundary @error="onErrorCaptured">
        <template #error="{ error }">
          <BaseErrorAlert>{{ error }}</BaseErrorAlert>
        </template>
        <template v-if="Boolean(sanitizedData.length)">
          <BaseBlockRenderer
            v-for="item in sanitizedData"
            :key="item.blockType"
            :block-type="item.blockType"
            :data="item.data"
          />
        </template>
        <template v-else>
          <template v-if="Boolean(sanitizedDataFromSSG.length)">
            <BaseBlockRenderer
              v-for="item in sanitizedDataFromSSG"
              :key="item.blockType"
              :block-type="item.blockType"
              :data="item.data"
            />
          </template>
          <template v-else>
            <template v-if="!loadingClient">
              <BaseErrorAlert>
                Oops,! it looks like an error occurred please, reload the page.
              </BaseErrorAlert>
              
            </template>
          </template>
        </template>
      </NuxtErrorBoundary>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useCmsApi } from '~/composables';
import BaseBlockRenderer from '~/components/BaseBlockRenderer.vue';
import BaseErrorAlert from '~/components/BaseErrorAlert.vue';
import { useI18nStore } from '~/stores/i18nStore';
import { useI18n, definePageMeta, useRoute, createError, useServerSeoMeta, useSeoMeta, useNuxtApp } from '#imports';
import { AppLogger } from '~/lib/utils';
import { isArray } from 'lodash-es';
import type { CmsPage, WebSettings } from '~/types';
import { wagtailDataToBlocks } from '~/lib/transformers';
import { ErrorBoundaryHandler } from '~/lib/errors';

const { $sentry } = useNuxtApp();

definePageMeta({
  layout: false,
});

const i18nStore = useI18nStore();
const { locale } = useI18n();
const route = useRoute();
const currentLocale = locale.value;
const slug = route.params.slug || '';
const routeUrlPath = isArray(slug) ? slug.join('/') : slug;
const loadingClient = ref(true);
const sanitizedDataFromSSG = ref<any[]>([]);
const computedUrl = computed(() => {
  let finalPath = currentLocale;
  if (routeUrlPath && routeUrlPath.length) {
    finalPath += `/${routeUrlPath}`;
  }
  if (finalPath.endsWith('/')) {
    finalPath.replace(/\/$/, '');
  }

  AppLogger('[computedURL]:', finalPath);

  return `/pages/find/?url_path=/${finalPath}`;
});

if (typeof slug === 'string' && slug.endsWith('css')) {
  throw new Error('[BasePageRenderer] is trying to fetch a css resource!');
}

function getLayoutName (blocks: any[], pageType: string) {
  const isHomePage = pageType?.toLowerCase?.()?.endsWith?.('homepage');
  const hasHeroComponent = blocks.find(v => v.blockType === 'hero');
  return isHomePage || hasHeroComponent ? 'default-home' : 'default';
}

const { getBlocks, fetchFromCMSClientSide, getChildPages } = useCmsApi();
const { data: sanitizedData, meta, error, id: pageId } = await getBlocks(computedUrl.value);
const pageType = meta?.type as string || '';
const isBlogRoot = pageType.toLocaleLowerCase().endsWith('blogindexpage');
const layoutName = ref(getLayoutName(sanitizedData, pageType));

if (isBlogRoot) {
  const { data: blogChildPages } = await getChildPages(pageId);
  const blogGrid = sanitizedData.find(({ blockType }) => blockType === 'blog_articles_grid');
  if (blogGrid) {
    // Inject articles into it's grid block
    blogGrid.data = {
      ...blogGrid?.data,
      articles: blogChildPages.items,
    };
  }
}

if (error?.value?.statusCode === 404) {
  throw createError({
    statusCode: error.value.statusCode,
    fatal: true,
  });
}

if (!sanitizedData.length) {
  AppLogger('[sanitizedData]', {
    message: 'No blocks data found! Publish a page.',
  });
}

function onErrorCaptured (e: any) {
  ErrorBoundaryHandler(e, $sentry);
}

const seoMeta = {
  title: meta?.seo_title as string,
  description: meta.search_description as string,
  ogDescription: meta.search_description as string,
  ogTitle: meta.seo_title as string,
  ogLocale: meta.locale as string,
  ogImage: meta.seo_image_url as string || '',
};

useServerSeoMeta(seoMeta);
useSeoMeta(seoMeta);

const webSettings = inject('settings') as Record<string, any>;
const settings = webSettings.settings as WebSettings;
if (settings.available_locales.length > 1) {
  const res = await fetchFromCMSClientSide(computedUrl.value) as CmsPage;
  loadingClient.value = false;
  sanitizedDataFromSSG.value = wagtailDataToBlocks(res)?.data || [];
  layoutName.value = getLayoutName(sanitizedDataFromSSG.value, res.meta?.type);
  i18nStore.setPaths(res.meta.translations);
}
</script>
